<template>
  <div class="welcome"></div>
</template>

<script>
// import Button from "./components/buttons/Button";

export default {
  name: "Welcome",
  components: {
  },
  emits: ["newGame", "onBoarding"],
  // beforeCreate() {
  //   this.$gtm.trackEvent({
  //     event: 'dataLayer_initialized',
  //     page_name: 'new game',
  //   });
  // },
  mounted() {
    this.$emit('newGame');
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
/* .welcome {
  width: 100%;
  height: 100vh;
  background: var(--navy);
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
} */
</style>
